<template>
  <a-row v-if="skeletonLoader" :gutter="22" >
    <a-col :xs="24" :sm="12" :md="8" :lg="8" v-for="index in 3" :key="index">
      <ArticleCardSkeleton/>
    </a-col>
  </a-row>
  <a-row v-else :gutter="22">
    <button class="add-podcast-btn" @click="openAddVideoModal()">
      <i class="icon icon-add_plus"></i>
    </button>
  </a-row>
  <a-row v-if="!skeletonLoader && podcasts.length == 0" :gutter="22" >
    <p class="message">No podcast found</p>
  </a-row>
  <a-row v-else-if="!skeletonLoader" :gutter="[18, 18]">
    <a-col
      v-for="podcast in podcasts"
      :key="podcast"
      :xs="24"
      :sm="12"
      :md="12"
      :lg="12"
      :xl="6"
    > 
        <MediaCard 
          :videoUrl="podcast.link_content ? podcast.link_content : ''"
          :videoFileName="podcast.title"
          :id="podcast.id"
          :for="'Podcast'"
          :deleteBtn="false"
        />
    </a-col>
  </a-row>
  <UploadDocumentsModal 
   :visible="visibleUploadVideoModal"
   :folderId="this.$route.params.id"
   :modalTitle="'Podcast'"
   @closeModal="closeAddVideoModal()"
  />
</template>

<script>
import ArticleCardSkeleton from "../BaseComponents/ArticleCardSkeleton.vue"
import UploadDocumentsModal from "./UploadDocumentModal.vue";
import MediaCard from "./MediaCard.vue";
import folderMixin from '../../mixins/folders.mixnin.js';
export default {
  data() {
    return {
      visibleUploadVideoModal: false,
    };
  },
  mixins: [folderMixin],
  components: {
    ArticleCardSkeleton,
    UploadDocumentsModal,
    MediaCard
  },
  computed: {
    skeletonLoader() {
      return this.$store.getters["folders/getFolderPodcastContentLoader"];
    },
    podcasts() {
      return this.$store.getters["folders/getFolderPodcastContent"];
    },

  },
  methods: {
    openAddVideoModal() {
      this.visibleUploadVideoModal = true;
    },
    closeAddVideoModal(){
      this.visibleUploadVideoModal = false;
    },
  }
};
</script>

<style lang="scss" scoped>
.add-podcast-btn {
  background-color: $color-primary;
  height: 4.2rem;
  width: 4.2rem;
  border: none;
  outline: none;
  border-radius: 100% !important;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: auto;
  margin-right: 1.5rem;
  margin-bottom: 2rem;
  .icon {
    color: $color-white;
    font-size: 1.6rem;
  }
}
.message {
  font-size: 1.6rem;
  font-family: $font-primary;
  line-height: 2.3rem;
  color: $color-black;
  margin: 0 auto;
}
</style>
