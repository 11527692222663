<template>
  <a-modal :visible="isVisible" :title="count" footer="" class="user-list-modal" @cancel="handleCancel()">
    <div v-if="!isLoading">
      <div v-for="(user, index) in reactedUsers" :key="index" class="user-card">
        <div class="image">
          <img :src="user.profilePicture">
        </div>
        <h2>{{ user.userName }}</h2>
        <div class="reaction">
          <img class="image" :src="getUserReactionIcon(user.reaction)" alt="">
        </div>
      </div>
    </div>
    <a-skeleton v-else></a-skeleton>
  </a-modal>
</template>
<script>
export default {
  props: {
    showModal: {
      default: false,
      type: Boolean
    },
    modalTitle: {
      default: "All",
      type: String
    },
    reactedUsers: {
      default: () => [],
      type: Array
    },
    articleId: String,
    isLoading: {
      default: false,
      type: Boolean
    },
    allReaction: Object,
  },
  computed: {
    isVisible() {
      return this.showModal
    },
    count() {
      return `All  ${this.reactedUsers.length}`;
    }
  },
  data() {
    return {
    showReactionUsers: false
    }
  },
  methods: {
    handleCancel() {
      this.$emit('cancelModal', true)
    },
    getUserReactionIcon(reactionName) {
      const reaction = this.allReaction.find(
        (reaction) => reaction.name === reactionName
      );

      if (reaction) {
        return reaction.icon;
      }
      return '';
    },
  }
}
</script>
<style lang="scss">
.user-list-modal {
  width: 50rem !important;

  .ant-modal-content {
    .ant-modal-header {
      padding: 3rem 3rem;
      border-bottom: 1px solid rgba(135, 149, 157, 0.3);
      margin-bottom: 0;
      background-color: transparent;

      .ant-modal-title {
        font-size: 2.5rem;
        font-family: $font-secondary-bold;
      }
    }

    .ant-modal-body {
      padding: 1rem 3rem !important;

      .user-card {
        display: flex;
        align-items: center;
        margin: 0.5rem 0;
        padding: 1rem 0;
        cursor: pointer;

        &:first-child {
          margin: 0;
        }

        .image {
          width: 3rem;
          height: 3rem;
          margin-right: 1rem;

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            border-radius: 100%;
            background-color: rgb(190, 190, 190);
          }
        }

        h2 {
          font-size: 1.6rem;
          font-family: $font-secondary;
          margin-bottom: 0;
        }

        .reaction {
          width: 2rem;
          height: 2rem;
          margin-left: auto;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            border-radius: 100%;
          }
        }
      }
    }


  }


}
</style>