<template>
  <div class="discussion-card">
    <div class="discussion-card__header">
      <div class="discussion-card__header--image">
        <img
          :src=discussionComment.user.profile_picture
        />
      </div>
      <div class="discussion-card__header--name-date">
        <h2>{{ discussionComment.user.full_name }}</h2>
        <p>{{formatCommentDate(discussionComment.created_at)}}</p>
      </div>
      <div class="discussion-card__header--like-ellipse">
        <button
          class="btn"
          :class="[ discussionComment.current_user_like ? 'like' : 'liked' ]"
          @click="toggleLike(true)"
        >
          <i class="icon icon-thumbs_up"></i>
          <p v-if="discussionComment.like_count > 0">{{ discussionComment.like_count > 9 ? '9+' : discussionComment.like_count }}</p>
        </button>
        <button
          class="btn"
          :class="[ discussionComment.current_user_dislike ? 'like' : 'liked' ]"
          @click="toggleLike(false)"
        >
          <i class="icon icon-thumbs_up dislike"></i>
          <p v-if="discussionComment.dislike_count > 0">{{ discussionComment.dislike_count > 9 ? '9+' : discussionComment.dislike_count }}</p>
        </button>
        <a-dropdown @click.stop :trigger="['click']" placement="bottomRight">
          <a class="ant-dropdown-link">
            <i class="icon icon-vertical_three-dots"></i>
          </a>
          <template #overlay>
            <a-menu>
              <a-menu-item key="1" @click="editComment(discussionComment)">
                <a>Edit</a>
              </a-menu-item>
              <a-menu-item key="1" @click="showDeleteCommentModel()">
                <a class="remove-btn">Delete</a>
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
      </div>
    </div>
    <div class="discussion-card__body">
      <div v-if="!isCommentEditing" class="discussion-card__body--description" v-html="linkifiedComment"></div>
      <div v-else class="discussion-card__body--edit">
        <div class="btn-textarea">
          <textarea name="" id="" rows="2" v-model="editedComment"></textarea>
          <button class="send-btn" @click="updateComment(discussionComment)">
            <the-loader v-if="loading"></the-loader>
            <i v-else class="icon icon-send"></i>
          </button>
        </div>
        <button class="cancel-btn" @click="cancelEditComment()">Cancel</button>
      </div>
      <div v-if="!isCommentEditing && links" class="discussion-card__body--description">
        <div v-for="(link, index) in links" :key="index" class="link-preview">
          <a :href="link.url" target="_blank" rel="noopener noreferrer" class="link-card">
            <div class="link-card__image">
              <img v-if="link.preview.image" :src="link.preview.image" alt="Link preview" />
            </div>
            <div class="link-card__content">
              <h2 class="link-card__content--title">
                {{ link.preview.title }}
              </h2>
              <div class="link-card__content--author" v-if="link.preview.authorName">
                <img v-if="link.preview.authorImage" :src="link.preview.authorImage" alt="Author image" />
                <p>
                  By {{ link.preview.authorName }} | Published: {{ link.preview.publishDate }}
                </p>
              </div>
            </div>
          </a>
        </div>
      </div>
      <div class="discussion-card__body--reply-btn" v-if="showReplyCommentButton">
        <button class="btn" @click="addReply()">
          <img src="@/assets/icons/share2.png" />
          Reply
        </button>
      </div>
    </div>
  </div>
  <delete-modal
    :visible="showCommentDeleteModal"
    @handle-close="showCommentDeleteModal = false"    
    title="Are you sure you want to delete this comment?"
    confirmText="Yes, Delete"
    cancelText="No, Don't Delete"
    :loading="deleteLoader"
    @delete-record="deleteComment()"
  ></delete-modal>
</template>

<script>
import TheLoader from '../BaseComponents/TheLoader.vue';
import DeleteModal from '../BaseComponents/DeleteModal.vue';
export default {
  data() {
    return {
      isLiked1: false,
      isLiked2: false,
      editedComment:'',
      isCommentEditing:false,
      loading: false,
      loader:false,
      showCommentDeleteModal:false,
      isShowReplyBox:false,
      repliedComment: '',
      links: []
    };
  },
  components: {
    TheLoader,
    DeleteModal
  },
  props: {
    link: Boolean,
    discussionComment:{
      required:true,
      default:{}
    },
    showReplyCommentButton: {
      type: Boolean,
      default: true,
    },
    closeEditLoader:Boolean,
    deleteLoader:Boolean
  },
  // mounted(){
  //   this.linkifiedComment()
  // },
  watch:{
    closeEditLoader:{
      handler(val){
        if(val){
          this.loading = false
          this.isCommentEditing = false
        }
      },
      immediate:true
    },
  },
  computed: {
    linkifiedComment() {
      return this.checkCommentFormat(this.discussionComment.comment);
    }
  },
  mounted() {
    this.initializeLinkPreviews();
  },
  methods: {
    checkCommentFormat(comment) {
      const urlPattern = /(\b(?:https?:\/\/)?(?:www\.)?[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(\/\S*)?\b)/g;
      
      const linkComment = comment.replace(urlPattern, (match) => {
        const url = match.startsWith('http') || match.startsWith('www.') ? match : 'http://' + match;
        return `<a href="${url}" target="_blank" rel="noopener noreferrer">${match}</a>`;
      });

      return linkComment;
    },
    async initializeLinkPreviews() {
      // Extract URLs from the comment
      const urlPattern = /(\b(?:https?:\/\/)?(?:www\.)?[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(\/\S*)?\b)/g;
      const urls = [];
      (this.discussionComment.comment || '').replace(urlPattern, (match) => {
        const url = match.startsWith('http') || match.startsWith('www.') ? match : 'http://' + match;
        urls.push(url);
        return match;
      });

      // Fetch link previews
      if (urls.length > 0) {
        this.fetchLinkPreviews(urls);
      }
    },
    async fetchLinkPreviews(urls) {
      this.links = await Promise.all(urls.map(async (url) => {
        try {
          const response = await fetch(`https://api.allorigins.win/get?url=${encodeURIComponent(url)}`);
          if (!response.ok) {
            throw new Error(`Network response was not ok ${response.statusText}`);
          }
          const { contents } = await response.json();
          const parser = new DOMParser();
          const doc = parser.parseFromString(contents, 'text/html');
          const title = doc.querySelector('title')?.textContent || 'No title found';
          const description = doc.querySelector('meta[name="description"]')?.getAttribute('content') || 'No description found';
          const image = doc.querySelector('meta[property="og:image"]')?.getAttribute('content') || '';
          
          // Extract additional metadata
          const authorImage = doc.querySelector('meta[property="article:author:image"]')?.getAttribute('content') ||
                              doc.querySelector('meta[name="author-image"]')?.getAttribute('content') || '';
          const authorName = doc.querySelector('meta[property="article:author"]')?.getAttribute('content') ||
                            doc.querySelector('meta[name="author"]')?.getAttribute('content') || 'No author found';
          const publishDate = doc.querySelector('meta[property="article:published_time"]')?.getAttribute('content') ||
                              doc.querySelector('meta[name="publish_date"]')?.getAttribute('content') ||
                              doc.querySelector('meta[property="publish_date"]')?.getAttribute('content') || 'No publish date found';
          return {
            url,
            preview: {
              title,
              description,
              image,
              authorImage,
              authorName,
              publishDate
            }
          };
        } catch (error) {
          console.error('Error fetching link preview:', error);
          return {
            url,
            preview: null
          };
        }
      }));
    },
    toggleLike(isLike) {
      console.log(isLike, this.discussionComment.current_user_like)
      if((isLike && this.discussionComment.current_user_like) || (!isLike && this.discussionComment.current_user_dislike) )
        this.$emit('deleteCommentLike',this.discussionComment)
      else
      this.$emit('likeComment',{discussionComment:this.discussionComment, action:{action:isLike ? "like" : "dislike"}})      
    },
    deleteComment(){
      this.loading = true
      this.$emit('deleteComment',this.discussionComment)
      this.loading = false
    },
    showDeleteCommentModel(){
      this.showCommentDeleteModal = true
    },
    editComment(discussionComment){
      this.editedComment = discussionComment.comment
      this.isCommentEditing = true
    },
    updateComment(discussionComment){
       discussionComment.editedComment = this.editedComment
       this.loading = true
       this.$emit('updateComment',discussionComment)
    },
    cancelEditComment(){
      this.editedComment = null
      this.isCommentEditing = false
    },
    showReplyBox(){
      this.isShowReplyBox = true
    },
    addReply(){
      console.log("EMITTING")
      this.$emit('replyComment', this.discussionComment)
    },
    formatCommentDate(inputDate) {
      // this function is used to format date of created_at of a comment
    const months = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    const date = new Date(inputDate);
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();
    let hour = date.getHours();
    const minute = date.getMinutes();
    const period = hour >= 12 ? 'PM' : 'AM';

    // Convert hour from 24-hour format to 12-hour format
    if (hour > 12) {
        hour -= 12;
    } else if (hour === 0) {
        hour = 12;
    }

    return `Posted on ${day} ${month} ${year} | ${hour}:${minute.toString().padStart(2, '0')} ${period}`;
    }
  },
};
</script>
<style lang="scss">
.discussion-card {
  background-color: #f5f5f5;
  border: 1px solid #00000033;
  border-radius: 1rem;
  padding: 2.3rem 1.6rem 2rem;
  margin: 2.4rem 0;
  position: relative;
  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 2.6rem;
    &--image {
      width: 3.8rem;
      height: 3.8rem;
      border-radius: 100%;
      margin-right: 0.9rem;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        border-radius: 100%;
        background-color: rgb(220, 220, 220);
      }
    }
    &--name-date {
      h2 {
        font-size: 1.4rem;
        font-family: $font-primary-medium;
        font-weight: 500;
        color: $color-black;
        line-height: 1.8rem;
        margin-bottom: 0;
      }
      p {
        font-size: 1.2rem;
        font-family: $font-primary;
        color: #757575;
        line-height: 1.6rem;
        margin-bottom: 0;
      }
    }
    &--like-ellipse {
      display: flex;
      align-items: center;
      gap: 1.7rem;
      margin-left: auto;
      .btn {
        border: none;
        outline: none;
        background-color: transparent;
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 0.8rem;
        .icon {
          color: #1c1b1f;
          font-size: 1.7rem;
          display: block;
          margin-bottom: auto;
        }
        .dislike {
          transform: rotate(-180deg);
        }
        p {
          font-size: 1.4rem;
          margin-bottom: 0;
          color: $color-black;
          line-height: 1.6rem;
          font-family: $font-primary-medium;
        }
      }
      .like {
        .icon {
          color: $color-primary;
        }
      }
      .ant-dropdown-link {
        margin-left: 1rem;
        .icon {
          color: #1c1b1f;
          font-size: 1.6rem;
          margin-bottom: 0.2rem;
        }
      }
    }
  }
  &__body {
    &--description {
      font-size: 1.6rem;
      font-family: $font-primary;
      color: $color-black;
      line-height: 2.1rem;
      margin-bottom: 0;
    }
    &--edit {
      width: 100%;
      .btn-textarea {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: $color-white;
        padding: 1rem 1rem;
        margin-bottom: 0.2rem;
        border-radius: 0.5rem;
        textarea {
          width: 100%;
          background-color: transparent;
          border: none;
          outline: none;
          font-size: 1.6rem;
          font-family: $font-primary;
          line-height: 1.8rem;
          resize: none;
          border-radius: 0 !important;
          &::-webkit-scrollbar-track {
            background-color: #03030309;
          }

          &::-webkit-scrollbar {
            width: 0.4rem;
            background-color: transparent;
            border-radius: 4rem;
          }

          &::-webkit-scrollbar-thumb {
            background-color: $color-primary;
            border-radius: 4rem;
          }
        }
        .send-btn {
          border: none;
          height:fit-content;
          width: fit-content;
          line-height: normal;
          margin-left: 1rem;
          cursor: pointer;
          background-color: transparent;
          display: flex;
          align-items: center;
          .icon {
            font-size: 2rem;
            color: $color-primary;
          }
          .ant-spin {
            margin-right: 0.5rem;
            .anticon {
              font-size: 1.5rem !important;
              .anticon-spin {
                fill: $color-primary;
              }
            }
          }
        }
      }
      .cancel-btn {
        border: none;
        background-color: transparent;
        color: $color-black;
        font-size: 1.2rem;
        font-family: $font-primary-medium;
        opacity: 0.6;
        cursor: pointer;
        margin-left: 0.5rem;
      }
    }
    .link-card {
      border: 1px solid $color-dark-grey-5;
      border-radius: 1rem;
      background-color: $color-white;
      display: flex;
      align-items: center;
      margin: 2.7rem 0 1.7rem;
      cursor: pointer;
      &__image {
        width: 10rem;
        height: 9.3rem;
        border-top-left-radius: 1rem;
        border-bottom-left-radius: 1rem;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-top-left-radius: 1rem;
          border-bottom-left-radius: 1rem;
        }
      }
      &__content {
        padding: 1.8rem 1.9rem;
        width: calc(100% - 10rem);
        &--title {
          font-size: 1.8rem;
          font-family: $font-primary-medium;
          font-weight: 500;
          color: $color-black;
          line-height: 2.4rem;
          margin-bottom: 0;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 1; /* number of lines to show */
          line-clamp: 1;
          -webkit-box-orient: vertical;
        }
        &--author {
          display: flex;
          align-items: center;
          margin-top: 1rem;
          img {
            border: 0.5px solid #0000001a;
            width: 2.3rem;
            height: 2.3rem;
            border-radius: 100%;
            margin-right: 1rem;
          }
          p {
            font-size: 1rem;
            font-family: $font-primary;
            color: $color-black;
            line-height: 1.3rem;
            margin-bottom: 0;
            opacity: 0.5;
          }
        }
      }
    }
    &--reply-btn {
      margin-top: 1.5rem;
      .btn {
        border: none;
        outline: none;
        background-color: transparent;
        height: fit-content;
        width: fit-content;
        display: flex;
        align-items: center;
        font-size: 1.4rem;
        font-family: $font-primary-medium;
        color: $color-black;
        line-height: 1.3rem;
        cursor: pointer;
        img {
          width: 1.3rem;
          display: block;
          transform: rotateY(180deg);
          margin-right: 0.6rem;
        }
      }
    }
    &--reply-comment {
      margin-top: 2rem;
      padding-left: 3rem;
      .input {
        background-color: $color-white;
        padding: 0 1.3rem;
        border-radius: 1rem;
        display: flex;
        align-items: center;
        img {
          width: 3rem;
          height: 3rem;
          border-radius: 100%;
          object-fit: fill;
          background-color: rgb(216, 216, 216);
        }
        textarea {
          padding: 1.4rem 1.5rem;
          margin: 1rem 0;
          border: none;
          font-size: 1.4rem;
          font-family: $font-primary;
          box-shadow: none;
          outline: none;
          line-height: 1.8rem;
          color: $color-black;
          background-color: transparent;
          resize: none;
          width: 100%;
          &::placeholder {
            color: #868686;
          }
          &::-webkit-scrollbar {
            display: none;
          }
        }
        .buttons {
          display: flex;
          align-items: center;
          .disabled {
            opacity: 0.5;
            cursor: not-allowed;
          }
          .ant-btn {
            width: 8rem;
            background-color: $color-primary;
            border: none;
            outline: none;
            height: 4rem;
            font-size: 1.4rem;
            font-family: $font-primary-medium !important;
            color: $color-white;
            border-radius: 100px !important;
            span {
              line-height: normal;
            }
            .anticon {
              .anticon-spin {
                font-size: 1.8rem;
                color: $color-primary;
              }
            }
            &:hover,
            &:focus {
              background-color: $color-primary;
              color: $color-white;
            }
          }
        }
      }
    }
  }
}
</style>
