<template>
  <div class="media-card">
    <div @click="playVideo()" class="media-card__header">
      <img v-if="thumbnailUrl" :src="thumbnailUrl" alt="Video Thumbnail" class="bg-image" />
      <img v-else src="@/assets/images/whiteBg.jpg"  class="bg-image" />
      <div class="play-icon">
        <img src="@/assets/icons/play-white.svg" />
      </div>
      <button @click.stop="deleteVideo(id)" class="delete-btn" v-if="deleteBtn">
        <i class="icon icon-dustbin"></i>
      </button>
      <a-dropdown :trigger="['click']" class="dropdown" @click.stop="" placement="bottomRight" v-else>
        <a class="ant-dropdown-link">
          <i class="icon icon-vertical_three-dots"></i>
        </a>
        <template #overlay>
          <a-menu>
            <a-menu-item key="0">
              <a>Add Folder</a>
            </a-menu-item>
            <a-menu-item key="1">
              <a @click="copyUrl(videoUrl)">Copy Link</a>
            </a-menu-item>
            <a-menu-item key="2">
              <a class="delete-dropdown-btn" @click="deleteVideo(id)">Delete</a>
            </a-menu-item>
          </a-menu>
        </template>
      </a-dropdown>
    </div>
    <div class="media-card__title">
      <h1>{{ videoFileName }}</h1>
    </div>
  </div>
  <VideoViewModal
    :visible="visibleVideoModal"
    :videoUrl="embedUrl"
    :videoFileName="videoFileName"
    @closeModal="closeVideoModal()"
  />
  <delete-modal
    :visible="visibleDeleteModal"
    @handle-close="visibleDeleteModal = false"
    :title="`Are you sure you want to remove this ${this.for}?`"
    confirmText="Yes, Remove"
    cancelText="No, Don't Remove"
    :loading="onRemoveLink"
    @delete-record="deleteLink"
  ></delete-modal>
</template>

<script>
import VideoViewModal from './VideoViewModal.vue';
import DeleteModal from "../BaseComponents/DeleteModal.vue";
import folderMixin from '../../mixins/folders.mixnin.js';

export default {
  components: {
    VideoViewModal,
    DeleteModal
  },
  props: {
    videoUrl: String,
    videoFileName: String,
    id: String,
    for: String,
    deleteBtn: Boolean
  },
  data(){
    return {
      visibleVideoModal: false,
      videoThumbnail: null,
      embedUrl: "",
      thumbnailUrl: "",
      visibleDeleteModal: false,
      onRemoveLink: false,
      contentToBeRemoved: "",
    }
  },
  mixins: [folderMixin],
  watch: {
    videoUrl: {
      handler(val) {
        this.embedUrl = this.getEmbedUrl(val);
        this.thumbnailUrl = this.getThumbnailUrl(val);
      },
      immediate: true
    }
  },
  computed: {
    media() {
      return this.$store.getters["folders/getFolderMediaContent"];
    },
    podcasts() {
      return this.$store.getters["folders/getFolderPodcastContent"];
    },
  },
  methods: {
    playVideo() {
      this.visibleVideoModal = true;
      this.activity('open')
    },
    closeVideoModal() {
      this.visibleVideoModal = false;
    },
    getEmbedUrl(url) {
      if (url.includes("youtube.com")) {
        // For YouTube videos
        return url.replace("/watch?v=", "/embed/");
      } else if (url.includes("vimeo.com")) {
        // For Vimeo videos
        const videoId = url.split("/").pop();
        return `https://player.vimeo.com/video/${videoId}`;
      } else {
        // Handle unsupported video platforms
        console.error("Unsupported video platform");
        return "";
      }
    },
    getThumbnailUrl(url) {
      if (url.includes("youtube.com")) {
        // For YouTube videos
        const videoId = url.split("v=")[1];
        return `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;
      } else if (url.includes("vimeo.com")) {
        // For Vimeo videos
        const videoId = url.split("/").pop();
        return `https://i.vimeocdn.com/video/${videoId}_640x360.jpg`;
      } else {
        console.error("Thumbnail URL construction not supported for this platform");
        return "";
      }
    },
    deleteVideo(id) {
      this.contentToBeRemoved = id;
      this.visibleDeleteModal = true;
    },
    async deleteLink() {
      try {
        this.onRemoveLink = true;
        const payload = { 
          folderId: this.$route.params.id,
          contentId: this.contentToBeRemoved
        }
        const response = await this.$store.dispatch('folders/deleteFolderContent', payload);
        if (response.status === 204) {
          this.visibleDeleteModal = false;
          const list = this.for === 'Link' ? this.media : this.podcasts;
          const updatedMediaList = list.findIndex((val) => val.id === this.contentToBeRemoved);
          if (updatedMediaList !== -1) {
            list.splice(updatedMediaList, 1);
          }
          this.activity('remove')
        }
          this.onRemoveLink = false;
        }
      catch (err) {
        console.log(err);
      }
    },
    copyUrl(val) {
      let tempInput = document.createElement("textarea");
      tempInput.value = val;
      document.body.appendChild(tempInput);
      tempInput.select();
      document.execCommand("copy");
      document.body.removeChild(tempInput);
    },
    activity(type) {
      this.createActivity(this.id, 'folder_content', type);
    }
  }
}
</script>

<style lang="scss">
.media-card {
  &__header {
    height: 20rem;
    width: 100%;
    position: relative;
    transition: all 0.3s ease-in-out;
    border-start-end-radius: 1rem;
    border-start-start-radius: 1rem;
    .bg-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-start-end-radius: 1rem;
      border-start-start-radius: 1rem;
    }
    .play-icon {
      display: flex;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: center;
      background-color: rgba(0, 0, 0, 0.411);
      border-radius: 0;
      cursor: pointer;
      transition: all 0.1s ease-in-out;
      border-start-end-radius: 1rem;
      border-start-start-radius: 1rem;
      img {
        width: 6rem;
        height: auto;
      }
      &:hover {
        background-color: rgba(0, 0, 0, 0.616);
      }
    }
    .delete-btn {
      position: absolute;
      right: 1rem;
      top: 1rem;
      background-color: $color-white;
      border: none;
      outline: none;
      border-radius: 100% !important;
      line-height: normal;
      height:  3rem;
      width: 3rem;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      .icon {
        font-size: 1.2rem;
        color: $color-black;
      }
    }
    .dropdown {
      position: absolute;
      right: 1rem;
      top: 1.5rem;
      .icon {
        color: $color-white;
        font-size: 1.5rem;
      }
    }
    // &:hover {
    //   .play-icon {
    //     display: flex;
    //   }
    // }
  }
  &__title {
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-end-end-radius: 1rem;
    border-end-start-radius: 1rem;
    padding: 1rem 2rem;
    h1 {
      font-size: 1.8rem;
      margin-bottom: 0;
      font-family: $font-primary-medium;
      line-height: 2.8rem;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 1; /* number of lines to show */
      line-clamp: 1;
      -webkit-box-orient: vertical;
    }
  }
}
</style>
