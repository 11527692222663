<template>
  <div class="topic-discussion-container">
    <button class="topic-discussion-container__btn" @click="discussionDrawer()">
      <div class="topic-discussion-container__btn--icon">
        <img src="@/assets/icons/mark_chat_unread_fill.svg" alt="Icon" />
      </div>
      <!-- <h2 class="topic-discussion-container__btn--title">Topic Chats</h2> -->
      <!-- <div class="topic-discussion-container__btn--count"> -->
        <!-- <p>{{ allChats.length }}</p> -->
      <!-- </div> -->
    </button>
    <!-- Topic Discussion Drawer -->
    <topic-discussion-drawer
      :drawerVisibility="showTopicDiscussionDrawer"
      :isPDBPage="isPDBPage"
      :isArticleView="isArticleView"
      @close-drawer="closeTopicDiscussionDrawer()"
    ></topic-discussion-drawer>

    <!-- <Transition name="fade" mode="out-in">
      <div class="topic-discussion-container__chats-container" v-if="visibleDiscussions">
        <div class="topic-discussion-container__chats-container--header">
          <h1>Topic Chats</h1>
          <img  @click="toggleDiscussionPopup" src="@/assets/icons/close-fullscreen.svg" alt="Close Icon" />
        </div>
        <div class="topic-discussion-container__chats-container--body">
          <div class="header">
            <div class="topic-title">
              <img v-if="topicType === 'Private'" src="@/assets/icons/private-icon.svg" alt="Icon" />
              <img v-else src="@/assets/icons/public-icon.svg" alt="Icon" />
              <p>{{ searchedTopic.interest.interest }} ({{ topicType }})</p>
            </div>
            <button @click="openInvitationModal" class="btn">
              <img src="@/assets/icons/add-user-icon.svg" alt="Icon" />
            </button>
          </div>
          <div v-if="isChatsLoading" class="body">
            <a-skeleton
              class="body__skeleton"
              active
              :title="null"
              :paragraph="{ rows: 4, width: ['100%', '100%', '80%', '50%'] }"
            />
          </div>
          <div v-else-if="!isChatsLoading && allChats.length == 0" class="body">
            <p class="body__message">No discussions found</p>
          </div>
          <div v-else class="body scrollBottom" ref="onTop" @scroll="LoadMoreChats" id="on-top">
            <div v-if="isMoreChatsLoading" class="body__loader">
              <the-loader/>
            </div>
            <div v-for="(discussion, index) in allChats"
              :key="discussion.id">
            <button v-if="shouldDisplayDate(index)" class="day-btn">
              <p>{{  manageTime(discussion.created_at, index)  }}</p>
              <img src="@/assets/icons/keyboard_arrow_down.svg" alt="Icon" />
            </button>
            <div
              class="message-container"
            >
              <div class="message-container__message">
                <div class="image">
                  <img
                    :src="discussion.user.profile_picture"
                    alt="User Image"
                  />
                </div>
                <div class="details">
                  <h2>{{ discussion.user.full_name }}</h2>
                  <p>
                    {{ discussion.message }}
                  </p>
                </div>
                <div v-if="isLoggedInUser != discussion.user.id" class="dropdown">
                  <a-dropdown :trigger="['click']" placement="bottomRight">
                    <a class="ant-dropdown-link">
                      <i class="icon icon-vertical_three-dots"></i>
                    </a>
                    <template #overlay>
                      <a-menu>
                        <a-menu-item @click="ShowReportModal(discussion.id)" key="0">
                          <a>Report Comment</a>
                        </a-menu-item>
                      </a-menu>
                    </template>
                  </a-dropdown>
                </div>
              </div>
              <p class="message-container__date">
                {{ discussion.human_time }}
              </p>
            </div>
            </div>
          </div>
          <div class="footer">
            <div class="footer__image">
              <img
                :src="userInformation.profile_picture"
                alt="User Image"
              />
            </div>
            <textarea
              placeholder="Type your thoughts..."
              rows="1"
              class="footer__textarea"
              v-model="addComment"
              @keydown.enter.exact.prevent
              @keyup.enter.exact="createChat()"
            ></textarea>
            <button 
              :disabled="btnState" 
              :class="{ 'footer__disable-btn': btnState }" 
              @click="createChat()" 
              class="footer__btn"
              
            >
              <img src="@/assets/icons/send-icon.svg" alt="Icon" />
            </button>
          </div>
        </div>
      </div>
    </Transition> -->
  </div>

  <!-- Report a discussion comment -->
  <!-- <report-comment-modal
  :visibleModal="visibleReportModal"
  :instanceId="discussionId"
  @closeModal="closeReportModal"
  ></report-comment-modal> -->

  <!-- Invite on discussion -->
  <!-- <invite-to-discussion
    :visible="visibleInvitationModal"
    @handle-close="closeInvitationModal"
    @show-success-modal="invitationSubmitted"
    :selectedTopic="invitedTopic"
    :interestGroupId="interestGroupId"
  ></invite-to-discussion> -->

  <!-- Invitaion success Message Modal -->
  <!-- <message-toaster 
  :visible="showMessageModal"
  :message="message"
  @handle-close="showMessageModal = false"
  >
  </message-toaster> -->
</template>

<script>
// import ReportCommentModal from "./ReportCommentModal.vue";
// import InviteToDiscussion from "../../PdbComponents/InviteToDiscussionModal.vue";
// import MessageToaster from "../MessageToaster.vue";
// import TheLoader from "../../BaseComponents/TheLoader.vue";
import TopicDiscussionDrawer from "../../TopicDiscussion/TopicDiscussionDrawer.vue";

export default {
  components: {
    // ReportCommentModal,
    // InviteToDiscussion,
    // TheLoader
    TopicDiscussionDrawer
  },
  props:{
    isPDBPage:Boolean,
    isArticleView: Boolean
  },
  data() {
    return {
      // visibleDiscussions: false,
      // visibleReportModal: false,
      // addComment: "",
      // btnState: true,
      // visibleInvitationModal: false,
      // showMessageModal: false,
      // message: {},
      // lastDisplayedDate: null,
      // isMoreChatsLoading: false,
      // discussionId: ""
      showTopicDiscussionDrawer: false
    };
  },
  // watch: {
  //   isChatsLoading(val) {
  //   if (!val) {
  //     this.scrollChatsToBottom();
  //     }
  //   },
  //   visibleDiscussions(val) {
  //    if (val) {
  //     this.scrollChatsToBottom();
  //     }
  //   },
  //   addComment(val) {
  //     if (val) {
  //       this.btnState = false;
  //     } else {
  //       this.btnState = true;
  //     }
  //   }
  // },
  // mounted() {
  //   this.emitter.on('stop-load-chats', (val) => {
  //     this.isMoreChatsLoading = val;
  //   });
  // },
  // computed: {
  //   searchedTopic() {
  //     return this.$store.getters["discover/getTopicDetails"];
  //   },
  //   topicType() {
  //     const tpye = this.searchedTopic.interest.is_private ? "Private" : "Public"; 
  //     return tpye;
  //   },
  //   userInformation() {
  //     return this.$store.getters["profile/userInformation"].profile;
  //   },
  //   isChatsLoading(){
  //     return this.$store.getters["discussion/getchatsSkeletonLoader"];
  //   },
  //   interestGroupId(){
  //     return this.$store.getters["article/getInterestGroupId"];
  //   },
  //   invitedTopic() {
  //     return {
  //       id: this.searchedTopic.interest.id,
  //       name: this.searchedTopic.interest.interest
  //     }
  //   },
  //   allChats() {
  //     return this.$store.getters["discussion/getTopicChats"];
  //     // const publicDiscussions = this.$store.getters["discussion/getPublicDiscussionComments"];
  //     // const privateDiscussions = this.$store.getters["discussion/getPrivateDiscussionComments"];

  //     // if (this.topicType === 'Private') {
  //     //   return privateDiscussions;
  //     // } else if(this.topicType === 'Public'){
  //     //   return publicDiscussions;
  //     // }
  //     // return [];
  //   },
  //   isLoggedInUser() {
  //     return this.$store.getters["profile/currentLoginUser"];
  //   },
  // },
  methods: {
    discussionDrawer() {
      this.showTopicDiscussionDrawer = true
    },
    closeTopicDiscussionDrawer() {
      this.showTopicDiscussionDrawer = false
    }
    // openInvitationModal() {
    //   this.visibleInvitationModal = true;
    // },
    // closeInvitationModal() {
    //   this.visibleInvitationModal = false;
    // },
    // invitationSubmitted(){
    //   this.showMessageModal = true;
    //   this.message = {
    //     title: "Invitation Sent Successfully",
    //     type: "success",
    //   };
    // },
    // ShowReportModal(id) {
    //   console.log(id)
    //   this.discussionId = id;
    //     this.visibleReportModal = true;
    // },
    // closeReportModal(){
    //     this.visibleReportModal = false;
    // },
    // async createChat() {
    //   try {
    //     this.btnState = true;
    //     const payload = {
    //       message: this.addComment,
    //       parent: null,
    //       interest_id: this.searchedTopic.interest.id,
    //     };
    //     const response = await this.$store.dispatch(
    //       "discussion/createChattingMessage",
    //       payload
    //     );
    //     if (response.status === 201) {
    //       this.addComment = "";
    //       this.allChats.push(response.data);
    //       this.scrollChatsToBottom();
    //     }
    //   } catch (err) {
    //     console.log(err);
    //   }
    //   this.btnState = false;
    // },
    // manageTime(date, index) {
    // const discussionDate = new Date(date);
    // const currentDate = new Date();

    // const prevDiscussionDate = index > 0 ? new Date(this.allChats[index - 1].created_at) : null;

    //   if (!prevDiscussionDate || 
    //       discussionDate.getDate() !== prevDiscussionDate.getDate() ||
    //       discussionDate.getMonth() !== prevDiscussionDate.getMonth() ||
    //       discussionDate.getFullYear() !== prevDiscussionDate.getFullYear()) {
          
    //       this.lastDisplayedDate = discussionDate;
          
    //       const options = { month: 'long', day: 'numeric', year: 'numeric' };
    //       const formattedDate = discussionDate.toLocaleDateString(undefined, options);

    //       if (discussionDate.toDateString() === currentDate.toDateString()) {
    //           return 'Today';
    //       }
    //       else if (discussionDate.getDate() === currentDate.getDate() - 1 &&
    //               discussionDate.getMonth() === currentDate.getMonth() &&
    //               discussionDate.getFullYear() === currentDate.getFullYear()) {
    //           return 'Yesterday';
    //       }
    //       else {
    //           return formattedDate;
    //       }
    //   }
    
    //     return null;
    // },
    // shouldDisplayDate(index) {
    //   if (index === 0) {
    //     return true;
    //   }
      
    //   const currentDate = new Date(this.allChats[index].created_at);
    //   const prevDate = new Date(this.allChats[index - 1].created_at);
    //   return currentDate.toDateString() !== prevDate.toDateString();
    // },
    // LoadMoreChats(event) {
    //   if (event.target.scrollTop == 0) {
    //     this.emitter.emit("load-more-chats");
    //     this.isMoreChatsLoading = true;
    //   }
    // },
    // scrollChatsToBottom() {
    //   this.$nextTick(() => {
    //     const element = document.querySelector('.scrollBottom');
    //     if (element) {
    //       const container = document.getElementById('on-top');
    //       container.scrollTop = container.scrollHeight - container.clientHeight;
    //     }
    //   });
    // }
  },
};
</script>

<style lang="scss">
.topic-discussion-container {
  position: fixed;
  bottom: 4.8rem;
  z-index: 444;
  right: 4.8rem;
  &__btn {
    background-color: $color-primary;
    display: flex;
    align-items: center;
    padding: 0.9rem 0.8rem;
    border-radius: 100px !important;
    border: none;
    outline: none;
    cursor: pointer;
    height: 5.9rem;
    width: 6.1rem;
    &--icon {
      width: 4.3rem;
      height: 4.3rem;
      background-color: $color-black;
      border-radius: 100%;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      img {
        width: 2rem;
        height: auto;
        margin-left: 1px;
        margin-top: 2px;
      }
    }

    // &--title {
    //   color: $color-white;
    //   font-size: 1.6rem;
    //   font-family: $font-primary-medium;
    //   line-height: 2.1rem;
    //   margin: 0 0.6rem;
    // }
    // &--count {
    //   width: 3rem;
    //   height: 3rem;
    //   background-color: #ffffff33;
    //   display: flex;
    //   align-items: center;
    //   justify-content: center;
    //   border-radius: 100%;
    //   margin-right: 0.4rem;
    //   p {
    //     color: $color-white;
    //     font-size: 1.2rem;
    //     font-family: $font-primary;
    //     margin-bottom: 0;
    //     line-height: 1.6rem;
    //   }
    // }
  }
  /* &__chats-container {
    position: absolute;
    bottom: 7rem;
    background-color: $color-white;
    height: 62vh;
    width: 40rem;
    right: 0;
    border-radius: 10px;
    border: 1px solid $color-dark-grey-5;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
    &--header {
      display: flex;
      align-items: center;
      position: sticky;
      top: 0;
      padding: 2.4rem 2.9rem;
      border-bottom: 1px solid $color-dark-grey-5;
      h1 {
        color: $color-black;
        font-size: 2.4rem;
        font-family: $font-primary-bold;
        line-height: 2.1rem;
        margin-bottom: 0;
      }
      img {
        width: 2rem;
        height: 2rem;
        margin-left: 1.7rem;
        cursor: pointer;
      }
    }
    &--body {
      .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1.1rem 2.5rem;
        border-bottom: 1px solid $color-dark-grey-5;
        .topic-title {
          display: flex;
          align-items: center;

          img {
            width: 1.6rem;
            height: auto;
            margin-right: 1rem;
          }
          p {
            color: $color-black;
            font-size: 1.6rem;
            font-family: $font-primary-medium;
            line-height: 2.1rem;
            margin-bottom: 0;
          }
        }
        .btn {
          border: 1px solid $color-primary;
          border-radius: 100% !important;
          height: 4.4rem;
          width: 4.4rem;
          outline: none;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: transparent;
          cursor: pointer;
          img {
            width: 2.2rem;
            height: auto;
          }
        }
      }
      .body {
        overflow-y: auto;
        padding: 2.2rem;
        height: calc(62vh - 216px);
        position: relative;
        .day-btn {
          border: none;
          outline: none;
          background-color: $color-light-blue;
          border-radius: 100px !important;
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 2.6rem;
          width: fit-content;
          padding: 0 1.3rem;
          margin: 0 auto 2rem;
          cursor: pointer;
          position: sticky;
          top: 0;
          p {
            color: #264099;
            font-size: 1.2rem;
            font-family: $font-primary-medium;
            line-height: 1.8rem;
            margin-bottom: 0;
          }
          img {
            width: 0.8rem;
            height: auto;
            margin-left: 1rem;
          }
        }
        .message-container {
          background-color: #ededed;
          border-radius: 10px;
          margin: 1.6rem 1.2rem 1.6rem 1.4rem;
          padding: 1.2rem 1.2rem 1.2rem 1.5rem;
          &__message {
            display: flex;
            align-items: flex-start;
            .image {
              width: 3.5rem;
              height: 3.5rem;
              background-color: rgba(128, 128, 128, 0.459);
              border-radius: 100%;
              margin-left: -2.9rem;
              margin-top: -1.2rem;
              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 100%;
              }
            }
            .details {
              width: 100%;
              padding: 0 0.9rem;
              h2 {
                color: $color-black;
                font-size: 1.4rem;
                font-family: $font-primary-medium;
                line-height: 1.8rem;
                margin-bottom: 0.4rem;
              }
              p {
                color: $color-black;
                font-size: 1.4rem;
                font-family: $font-primary;
                line-height: 1.8rem;
                margin-bottom: 0;
              }
            }
            .dropdown {
              width: 1rem;
              .ant-dropdown-link {
                width: 100%;
                .icon {
                  color: #8d8d8d;
                  font-size: 1.5rem;
                }
              }
            }
          }
          &__date {
            color: #00000080;
            font-size: 1rem;
            font-family: $font-primary;
            line-height: 1.6rem;
            margin-bottom: 0;
            margin-left: auto;
            display: block;
            width: fit-content;
          }
        }
        &::-webkit-scrollbar-track {
          background-color: #ffffff3f;
        }
        &::-webkit-scrollbar {
          width: 4px;
          background-color: rgb(224, 224, 224);
          border-radius: 3rem;
        }
        &::-webkit-scrollbar-thumb {
          background-color: $color-primary;
          border-radius: 3rem;
        }
        &__skeleton {
          .ant-skeleton-content {
            .ant-skeleton-paragraph {
              li {
                height: 3rem;
                margin: 1rem 0;
                &:first-child {
                  margin-top: 0;
                }
              }
            }
          }
        }
        &__message {
          color: $color-black;
          font-size: 1.6rem;
          font-family: $font-primary;
          line-height: 2.1rem;
          margin-bottom: 0;
          text-align: center;
          margin-top: 2rem;
        }
        &__loader {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 1rem;
          .ant-spin {
            .anticon {
              font-size: 2rem !important;
              .anticon-spin {
                color: $color-primary;
              }
            }
          }
        }
      }
      .footer {
        border-top: 1px solid $color-dark-grey-5;
        padding: 2.6rem 2.3rem;
        position: relative;
        display: flex;
        align-items: center;
        position: absolute;
        bottom: 0;
        width: 100%;
        background-color: $color-white;
        border-end-end-radius: 10px;
        border-end-start-radius: 10px;
        &__image {
          position: absolute;
          top: -2rem;
          left: 2.3rem;
          box-shadow: 0px 4px 8.300000190734863px 0px #00000040;
          width: 3.5rem;
          height: 3.5rem;
          border-radius: 100%;
          background-color: rgba(128, 128, 128, 0.418);
          img {
            width: 100%;
            height: 100%;
            border-radius: 100%;
          }
        }
        &__textarea {
          border: none;
          width: 100%;
          outline: none;
          resize: none;
          font-size: 1.4rem;
          font-family: $font-primary;
          line-height: 1.8rem;
          color: $color-black;
          padding: 0.5rem 0;
          &::placeholder {
            color: #868686;
          }
          &::-webkit-scrollbar-track {
            background-color: #ffffff3f;
          }
          &::-webkit-scrollbar {
            width: 4px;
            background-color: rgb(224, 224, 224);
            border-radius: 3rem;
          }
          &::-webkit-scrollbar-thumb {
            background-color: $color-primary;
            border-radius: 3rem;
          }
        }
        &__btn {
          border: none;
          outline: none;
          margin-left: 1rem;
          background-color: transparent;
          cursor: pointer;
          img {
            width: 2.1rem;
            height: auto;
          }
        }
        &__disable-btn {
          opacity: 0.5;
          cursor: default;
        }
      }
    } 
  } */
}
/*.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transition: opacity 0.3s ease;
} */
</style>
