<template>
  <div class="folder-artricles-section">
  <a-row v-if="skeletonLoader" :gutter="22" >
    <a-col :xs="24" :sm="12" :md="8" :lg="8" v-for="index in 3" :key="index">
      <ArticleCardSkeleton/>
    </a-col>
  </a-row>
  <a-row v-else :gutter="22">
    <button class="folder-artricles-section__add-pdf-btn" @click="openAddDocumentModal()">
      <i class="icon icon-add_plus"></i>
    </button>
  </a-row>
  <a-row v-if="!skeletonLoader && folderArticles.length == 0" :gutter="22" >
    <p class="folder-artricles-section__message">No content found</p>
  </a-row>
  <a-row v-else-if="!skeletonLoader" :gutter="[18, 18]">
    <a-col
      v-for="data in folderArticles"
      :key="data.id"
      :xs="24"
      :sm="12"
      :md="12"
      :lg="12"
      :xl="8"
    >
      <article-card
        v-if="data.content === 'article'"
        :title="data.article.title"
        :author-name="data.article.authors"
        :url="data.article.article_url.url"
        :sourceLogo="data.article.logo"
        :source="data.article.article_url.source"
        :image="data.article.image"
        :text="data.article.description"
        :id="data.article.id"
        :date="data.article.pub_date"
        :ratings="data.article.rating"
        :aggregatedRating="data.article.aggregated_rating"
        :userReaction="data.article.user_reaction"
        :totalArticleReactions="data.article.article_reactions"
        :userReactionRepresentation="data.article.users_reaction_representation"
        :isInsightArticle="true"
        :showShareButton="true"
        :showSaveBtn="false"
        :showUnsaveBtn="false"
        :articleContentId="data.id"
        :articleEllipsis="true"
        @articleOpen="openArticle(data)"
      >
      </article-card>
      <PdfCard
        v-else
        :pdfFileName="data.title"
        :pdfUrl="data.file_content"
        :id="data.id"
      />
    </a-col>
  </a-row>
  </div>
  <UploadDocumentsModal 
   :visible="visibleUploadDocumentModal"
   :folderId="this.$route.params.id"
   :modalTitle="'Document'"
   @closeModal="closeAddDocumentModal()"
  />
</template>

<script>
import ArticleCardSkeleton from "../BaseComponents/ArticleCardSkeleton.vue"
import ArticleCard from "../BaseComponents/ArticleCard.vue";
import UploadDocumentsModal from "./UploadDocumentModal.vue";
import PdfCard from "./PDFCard.vue";
import folderMixin from '../../mixins/folders.mixnin.js';

export default {
  components: {
    ArticleCard,
    ArticleCardSkeleton,
    UploadDocumentsModal,
    PdfCard
  },
  data() {
    return {
      visibleUploadDocumentModal: false
    }
  },
  props:{
    articleType:{
      required:true
    }
  },
  mixins: [folderMixin],
  computed: {
    skeletonLoader() {
      return this.$store.getters["folders/getFolderArticlesContentLoader"];
    },
    folderArticles() {
      return this.articleType === 'articles' ? this.$store.getters["folders/getFolderArticleContent"] : this.$store.getters["folders/getFolderSuggestedArticleContent"];
    },
  },
  methods: {
    openAddDocumentModal() {
      this.visibleUploadDocumentModal = true;
    },
    closeAddDocumentModal(){
      this.visibleUploadDocumentModal = false;
    },
    openArticle(data) {
      this.createActivity(data.id, 'folder_content', 'open');
    }
  }
};
</script>

<style lang="scss">
.folder-artricles-section {
  &__add-pdf-btn {
    background-color: $color-primary;
    height: 4.2rem;
    width: 4.2rem;
    border: none;
    outline: none;
    border-radius: 100% !important;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-left: auto;
    margin-right: 1.5rem;
    margin-bottom: 2rem;
    .icon {
      color: $color-white;
      font-size: 1.6rem;
    }
  }
  &__message {
    font-size: 1.6rem;
    font-family: $font-primary;
    line-height: 2.3rem;
    color: $color-black;
    margin: 0 auto;
  }
  .article-card-new {
    &__footer {
      .the-horizontal-reactions-bar {
        border-right: 1px solid rgba(0, 0, 0, 0.408);
      }
    }
  }
}
</style>
