<template>
  <div class="folder-article-view-section">
    <div class="folder-article-view-section__header">
      <div class="folder-article-view-section__header--title">
        <button @click="visibleFolderSideBar" class="side-bar-menu">
          <img src="@/assets/icons/menu.svg" />
        </button>
        <div class="folder-icon">
          <img src="../../assets/icons/glocal-folder-icon.svg" alt="Icon" />
        </div>
        <h1 v-if="parentFolder.folder" class="title">
          <span v-if="nestedChildFolder.folder">{{  nestedChildFolder.folder.name }}</span>
          <span v-else-if="childFolder.folder">{{  childFolder.folder.name }}</span>
          <span v-else>{{ parentFolder.folder.name}}</span>
        </h1>
      </div>
      <div class="folder-article-view-section__header--tabs-search">
        <div class="tabs">
          <button
            v-for="(tab, index) in tabs"
            :key="index"
            :class="{
              tabs__tab: true,
              'tabs__active-tab': activeTab === tab.id,
            }"
            @click="setActiveTab(tab.id)"
          >
            <span>{{ tab.label }}</span>
          </button>
        </div>
        <div class="search-container">
          <!-- <div v-if="folderUsers.length > 0 && folderType === 'institute'" class="search-container__users">
            <div class="search-container__users--count">
              <button  @click="visibleUserPermissionDrawer()" class="btn">See all</button>
              <p>{{ folderUsers.length }}</p>
            </div>
            <div class="search-container__users--user-images">
              <img
                v-for="image in folderUsers.slice(0, 3)"
                :key="image"
                :src="image.profilePicture"
                alt="image"
              />
            </div>
          </div> -->
          <div class="search-container__buttons">
            <a-tooltip class="link-tooltip" v-if="folderUsers.length > 0 && folderType === 'institute'">
              <template #title>Add Users</template>
              <button class="search-container__buttons--btn" @click="visibleUserPermissionDrawer()">
                <img src="@/assets/icons/network_icon_black.png" />
              </button>
            </a-tooltip>
            <QRCode @click.stop :folder="QRDetails" />
            <!-- <a-tooltip class="link-tooltip">
              <template #title>Downloads</template>
              <button class="search-container__buttons--btn">
                <img src="@/assets/icons/download-icon.png" />
              </button>
            </a-tooltip> -->
            <a-tooltip class="link-tooltip">
              <template #title>Activity Log</template>
              <button class="search-container__buttons--btn" @click="showActivityDrawer()">
                <img src="@/assets/icons/activity-log-icon.png" />
              </button>
            </a-tooltip>
          </div>
        </div>
      </div>
    </div>
    <div class="folder-article-view-section__body">
      <ArticleCollapseView :articleType="activeTab" v-if="activeTab === 'articles' || activeTab === 'suggested'" />
      <MediaSection v-if="activeTab === 'videos'" />
      <PodcastSection v-if="activeTab === 'podcasts'" />
    </div>
  </div>
  <Transition name="slide-fade">
    <FolderMenuSidebar v-if="openFolderBar" />
  </Transition>
  <!-- User permissions drawer -->
  <user-permissions-drawer
    :visibleDrawer="visibleUserPermissionsDrawer"
    :drawerPlacement="drawerPlacement"
    @close-drawer="closeUserPermissionsDrawer"
  >
  </user-permissions-drawer>
  <!-- Activity permissions drawer -->
  <activity-log-drawer 
   :drawerVisibility="activityDrawer"
    @close-drawer="closeActivityDrawer()"
  >
  </activity-log-drawer>
</template>

<script>
import MediaSection from "./MediaSection.vue";
import QRCode from "../BaseComponents/QRCodeScanner/QRCode.vue";
import FolderMenuSidebar from "./FolderMenuSidebar.vue";
import ArticleCollapseView from "./ArticleCollapseView.vue";
import PodcastSection from "./PodcastSection.vue";
import ArchiveFolders from "./ArchiveFolders.vue";
import UserPermissionsDrawer from "./UserPermissionsDrawer.vue";
import ActivityLogDrawer from "./ActivityLogDrawer.vue";
import { mapGetters, mapActions } from "vuex";
import foldersMixin from "../../mixins/folders.mixnin";
export default {
  data() {
    return {
      openFolderBar: false,
      activeTab: "articles",
      visibleUserPermissionsDrawer: false,
      activityDrawer: false,
      tabs: [
        { id: "articles", label: "Articles" },
        { id: "videos", label: "Videos" },
        { id: "podcasts", label: "Podcasts" },
        { id: "suggested", label: "Suggested Articles by Glocal" },
      ],
    };
  },
  mixins:[foldersMixin],
  components: {
    FolderMenuSidebar,
    QRCode,
    MediaSection,
    ArticleCollapseView,
    PodcastSection,
    UserPermissionsDrawer,
    ActivityLogDrawer
  },
  mounted() {
    this.emitter.on("close-folder-side-bar", () => {
      this.openFolderBar = false;
    });
  },
  methods: {
    visibleFolderSideBar() {
      this.openFolderBar = true;
    },
    setActiveTab(tabId) {
      this.activeTab = tabId;
    },
    visibleUserPermissionDrawer() {
      if (window.innerWidth < 576) {
        this.drawerPlacement = 'bottom'
      } else if (window.innerWidth > 576) {
        this.drawerPlacement = 'right'
      }
      this.visibleUserPermissionsDrawer = true
    },
    closeUserPermissionsDrawer() {
      this.visibleUserPermissionsDrawer = false
    },
    showActivityDrawer() {
      this.activityDrawer = true
    },
    closeActivityDrawer() {
      this.activityDrawer = false
    },
  },
  computed: {
    activeComponent() {
      return this.components[this.activeTab];
    },
    parentFolder() {
      return this.$store.getters["folders/getParentFolderDetails"];
    },
    childFolder() {
      return this.$store.getters["folders/getChildFolderDetails"];
    },
    nestedChildFolder() {
      return this.$store.getters["folders/getNestedChildFolderDetails"];
    },
    folderUsers() {
      return this.$store.getters["folders/getUsersWithFolderAccess"];
    },
    QRDetails() {
      let folder = this.childFolder.folder
        ? this.childFolder.folder
        : this.parentFolder.folder;
      return folder;
    },
  },
};
</script>

<style lang="scss">
.folder-article-view-section {
  height: calc(100vh - 80px);
  overflow-y: auto;
  &__header {
    padding: 1.9rem 5.4rem;
    position: sticky;
    top: 0;
    background-color: $color-white;
    z-index: 1;
    @include respond(tab-port) {
      padding: 1.9rem 1.4rem;
    }
    @include respond(phone-x-small) {
      padding: 1.9rem 2.4rem;
    }
    &--title {
      display: flex;
      align-items: center;
      width: fit-content;
      .side-bar-menu {
        display: none;
        margin-right: 2.5rem;
        line-height: normal;
        padding: 0;
        height: fit-content;
        width: fit-content;
        border: none;
        background-color: transparent;
        color: $color-black;
        outline: none;
        cursor: pointer;
        margin-top: 0.5rem;
        @include respond(tab-port) {
          display: block;
        }
        img {
          width: 3.2rem;
        }
      }
      .folder-icon {
        margin-right: 2rem;
        width: 2.4rem;
        height: auto;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .title {
        font-size: 2rem;
        font-family: $font-primary-medium;
        line-height: 2.3rem;
        color: $color-black;
        margin-bottom: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
        margin-top: 0.3rem;
        @include respond(laptop-small) {
          max-width: 74%;
        }
      }
    }
    &--tabs-search {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 3rem;
      @include respond(laptop-small) {
        flex-direction: column;
      }
      .tabs {
        width: auto;
        display: flex;
        align-items: center;
        gap: 1.6rem;
        overflow-x: auto;
        overflow-y: hidden;
        @include respond(laptop-medium) {
          width: 70%;
        }
        @include respond(laptop-small) {
          width: 100%;
          order: 2;
        }
        &__tab {
          padding: 1rem 1.6rem;
          display: flex;
          align-items: center;
          width: max-content;
          justify-content: center;
          border: none;
          outline: none;
          background-color: $color-light-grey-3;
          font-size: 1.6rem;
          font-family: $font-primary-medium;
          line-height: 2rem;
          color: $color-dark-grey-6;
          border-top-right-radius: 1rem !important;
          border-top-left-radius: 1rem !important;
          border-bottom-left-radius: 0 !important;
          border-bottom-right-radius: 0 !important;
          cursor: pointer;
          margin-bottom: 0;
          span {
            display: block;
            width: max-content;
          }
        }
        &__active-tab {
          background-color: $color-primary;
          color: $color-white;
        }
        &::-webkit-scrollbar {
          height: 0.4rem;
        }

        &::-webkit-scrollbar-track {
          background-color: $color-white;
          border-radius: 0.8rem;
          height: 0.4rem;
        }

        &::-webkit-scrollbar-thumb {
          background-color: $color-primary;
          border-radius: 0.8rem;
        }

        @include respond(phone-x-small) {
          &::-webkit-scrollbar {
            display: none;
          }
        }
      }
      .search-container {
        width: auto;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 1.6rem;
        margin-bottom: 0.8rem;
        padding-left: 1rem;
        @include respond(laptop-medium) {
          width: 30%;
        }
        @include respond(laptop-small) {
          width: 100%;
          order: 1;
          margin-bottom: 2rem;
        }
        &__buttons {
          display: flex;
          align-items: center;
          gap: 1.6rem;
          &--btn {
            width: 4.4rem;
            height: 4.4rem;
            border: none;
            outline: none;
            border-radius: 100% !important;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $color-white;
            cursor: pointer;
            background-color: #e1e8ff;
            img {
              width: 1.7rem;
              height: 1.7rem;
            }
          }
          .qr-code-scanner-container {
            &__btn {
              width: 4.4rem;
              height: 4.4rem;
              background-color: #e1e8ff;
            }
            &__scanner-dropdown {
              top: 7rem;
              right: -0.5rem;
            }
          }
        }
        &__search-bar {
          border: 1px solid $color-dark-grey-5;
          display: flex;
          align-items: center;
          padding: 0 1.8rem;
          border-radius: 100px;
          width: calc(100% - 136px);
          background-color: #efefef;
          max-width: 30rem;
          .icon {
            font-size: 1.7rem;
            color: $color-primary;
          }
          .icon-add_plus {
            transform: rotate(45deg);
            display: block;
            font-size: 1.4rem;
            color: $color-black;
            cursor: pointer;
          }
          .search-bar {
            border: none;
            padding: 0;
            padding-left: 1.3rem;
            background-color: transparent;
            height: 5.1rem;
            font-size: 1.4rem;
            outline: none;
            border: none;
            padding-right: 1rem;
            width: 100%;
            &::placeholder {
              color: $color-black;
              opacity: 0.5;
            }
          }
        }
        // &__users {
        //   display: flex;
        //   align-items: center;
        //   &--count {
        //     display: flex;
        //     align-items: center;
        //     margin-left: 1.5rem;
        //     button {
        //       background-color: red;
        //       border: none;
        //       outline: none;
        //       line-height: normal;
        //       width: max-content;
        //       height: fit-content;
        //       font-size: 1.2rem;
        //       font-family: $font-primary-medium;
        //       cursor: pointer;
        //       background-color: transparent;
        //       display: flex;
        //     }
        //     p {
        //       margin-bottom: 0;
        //       font-size: 1.3rem;
        //       font-family: $font-primary-medium;
        //       line-height: normal;
        //       margin-left: 0.5rem;
        //     }
        //   }
        //   &--user-images {
        //     display: flex;
        //     align-items: center;
        //     margin-left: 2rem;
        //     img {
        //       width: 3rem;
        //       height: 3rem;
        //       object-fit: cover;
        //       border-radius: 100%;
        //       border: 2px solid $color-white;
        //       margin-left: -1rem;
        //     }
        //   }
        // }
      }
    }
  }
  &__body {
    padding: 1.5rem 5.4rem;
    @include respond(tab-port) {
      padding: 1.5rem 1.4rem;
    }
    @include respond(phone-x-small) {
      padding: 1.5rem 2.4rem;
    }
  }
  &::-webkit-scrollbar {
    width: 0.5rem;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 0.8rem;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $color-primary;
    border-radius: 0.8rem;
    height: 5rem;
  }
}

.slide-fade-enter-active {
  transition: all 0.5s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
  transform: translateX(-100%);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(100%);
  opacity: 0;
}
</style>
