<template>
  <div class="all-justifications-container">
    <a-tabs v-model:activeTab="activeTab" @change="modalActiveTab">
      <a-tab-pane key="activeAccuracyTab" tab="Accuracy">
        <div v-if="skeletonLoading">
          <a-skeleton v-for="index in 5" :key="index" class="skeleton-loader-feedbacks" active :title="{ width: '100%' }" :paragraph="null" />
        </div>
        <p class="not-found" v-else-if="allRatings.length == 0">No justifications found</p>
        <div v-else v-for="feedback in allRatings" :key="feedback" class="user-comment">
          <div class="user-comment__image">
            <img :src="feedback.user.profile.profile_picture" />
          </div>
          <div class="user-comment__details">
            <div class="user-comment__details--name">
              <h1>{{ feedback.user.profile.full_name }}</h1>
              <div class="circle" :class="{
                'green': 'Accurate' === feedback.rate,
                'yellow': 'Somewhat Accurate' === feedback.rate,
                'red': 'Lacks Accuracy' === feedback.rate,
              }">
              </div>
            </div>
            <p class="user-comment__details--comment">
              {{ feedback.feedback }}
            </p>
          </div>
        </div>
      </a-tab-pane>
      <a-tab-pane key="activeCredibilityTab" tab="Credibility">
        <div v-if="skeletonLoading">
          <a-skeleton v-for="index in 5" :key="index" class="skeleton-loader-feedbacks" active :title="{ width: '100%' }" :paragraph="null" />
        </div>
        <p class="not-found" v-else-if="allRatings.length == 0">No justifications found</p>
        <div v-else v-for="feedback in allRatings" :key="feedback" class="user-comment">
          <div class="user-comment__image">
            <img :src="feedback.user.profile.profile_picture" />
          </div>
          <div class="user-comment__details">
            <div class="user-comment__details--name">
              <h1>{{ feedback.user.profile.full_name }}</h1>
              <div class="circle" :class="{
                'green': 'Credible' === feedback.rate,
                'yellow':  'Somewhat Credible' === feedback.rate,
                'red': 'Lacks Credibility' === feedback.rate,
              }"></div>
            </div>
            <p class="user-comment__details--comment">
              {{ feedback.feedback }}
            </p>
          </div>
        </div>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
export default {
  props: {
    visibleModal: Boolean,
    articleId: String,
    accuracyId: Object,
    credibilityId: Object
  },
  watch: {
    visibleModal: {
      handler(val) {
        if (val) {
          this.modalActiveTab('activeAccuracyTab');
          this.activeTab = "activeAccuracyTab"
        }
      },
      immediate: true
    },
  },
  data() {
    return {
      activeTab: "activeAccuracyTab",
      skeletonLoading: true,
      allRatings: []
    };
  },
  methods: {
    modalActiveTab(activeTab) {

      if (activeTab === 'activeAccuracyTab') {
        this.getAllRating(this.accuracyId.question.id);
      } else {
        this.getAllRating(this.credibilityId.question.id);
      }
    },
    async getAllRating(id) {
      try {
        this.skeletonLoading = true;
        const payload = {
          article_id: this.articleId,
          question_id: id
        }
        const response = await this.$store.dispatch("discover/seeAllJustifications", payload);
        if (response.status === 200) {
          this.skeletonLoading = false;
          this.allRatings = response.data;
        }
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>

<style lang="scss">
.all-justifications-container {
  position: relative;
  height: auto;
  padding: 3.2rem 1.8rem;

  .ant-tabs {
    height: 100%;

    .ant-tabs-bar {
      border: none;
      margin-bottom: 2.4rem;

      .ant-tabs-nav-container {
        padding-bottom: 2px;
        padding: 0 0.8rem;

        .ant-tabs-tab-arrow-show {
          display: none !important;
        }

        .ant-tabs-nav-wrap {
          border: none;

          .ant-tabs-nav-scroll {
            border: none;
            overflow-x: auto;

            &::-webkit-scrollbar {
              height: 0.3rem;
            }

            &::-webkit-scrollbar-track {
              background-color: $color-white;
              border-radius: 0.8rem;
              height: 0.3rem;
            }

            &::-webkit-scrollbar-thumb {
              background-color: $color-primary;
              border-radius: 0.8rem;
            }

            @include respond(phone-x-small) {
              &::-webkit-scrollbar {
                display: none;
              }
            }

            .ant-tabs-nav {
              border: none;
              padding-bottom: 0;

              .ant-tabs-tab {
                padding: 0.6rem 2rem;
                border-radius: 100px;
                font-size: 1.4rem;
                border: 1px solid $color-dark-grey-5;
                font-family: $font-primary;
                color: #434343;
                margin-right: 1.2rem;
                margin-bottom: 0.2rem;
              }

              .ant-tabs-tab-active {
                font-family: $font-primary-regular;
                background-color: $color-dark-grey-5;
                color: $color-black;
                opacity: 1;
              }

              .ant-tabs-ink-bar {
                display: none;
              }
            }

            .ant-tabs-ink-bar {
              display: none !important;
            }
          }
        }
      }
    }

    .ant-tabs-content {
      .ant-tabs-tabpane {
        overflow-x: hidden;
        overflow-y: auto;
        height: 40rem;
        padding: 0 0.8rem;
        .not-found{
          text-align: center;
        }
        .user-comment {
          display: flex;
          align-items: flex-start;
          background-color: rgba(201, 201, 201, 0.321);
          padding: 1.5rem;
          border-radius: 1rem;
          margin-bottom: 2rem;

          &__image {
            width: 3.5rem;
            height: auto;
            border-radius: 100%;

            img {
              width: 100%;
              height: 100%;
              background-color: $color-dark-grey-5;
              border-radius: 100%;
              object-fit: fill;
            }
          }

          &__details {
            width: 100%;
            margin-left: 1rem;

            &--name {
              display: flex;
              align-items: center;
              justify-content: flex-start;

              h1 {
                font-size: 1.6rem;
                font-family: $font-primary-medium;
                line-height: 2.2rem;
                color: $color-black;
                margin-bottom: 0;
              }

              .circle {
                width: 1.5rem;
                height: 1.5rem;
                background-color: transparent;
                border-radius: 100%;
                margin-left: 1rem;
              }

              .green {
                background-color: rgba(16, 196, 13, 0.678);
              }

              .yellow {
                background-color: #ffd900;
              }

              .red {
                background-color: #ec4242;
              }
            }

            &--comment {
              margin-top: 0.5rem;
              font-size: 1.4rem;
              font-family: $font-primary;
              line-height: 2rem;
              color: $color-black;
              margin-bottom: 0;
              opacity: 0.7;
            }
          }
        }

        .skeleton-loader-feedbacks {
          .ant-skeleton-content {
            .ant-skeleton-title {
              height: 8rem;
              margin: 0 0 2rem;
            }
          }
        }
      }

      .ant-tabs-tabpane::-webkit-scrollbar-track {
        background-color: #ffffff3f;
      }

      .ant-tabs-tabpane::-webkit-scrollbar {
        width: 4px;
        background-color: rgb(224, 224, 224);
        border-radius: 3rem;
      }

      .ant-tabs-tabpane::-webkit-scrollbar-thumb {
        background-color: $color-primary;
        border-radius: 3rem;
      }
    }
  }
}
</style>
