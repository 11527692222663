<template>
  <div :key="reRender" class="article-card-new" :class="{'article-card-new-large': cardSize}">
    <div
      @click="openArticle()"
      class="article-card-new__header"
      :class="{'article-card-new-large__header': cardSize}"
      :style="{
        backgroundImage: `linear-gradient(179.9deg, rgba(0, 0, 0, 0) 0.09%, #000000 90.3%), url(${image})`,
      }"
    >
      <h2 class="article-card-new__header--title" :class="{'article-card-new-large__header--title': cardSize}">
        {{ title }}
      </h2>
      <div class="article-card-new__header--auth-and-date" :class="{'article-card-new-large__header--auth-and-date': cardSize}">
        <img
          v-if="sourceLogo"
          :src="sourceLogo"
          alt="icon"
        />
        <img v-else
          class="logo-image"
          src="@/assets/icons/newspaper.svg" alt="icon"/>
        <p>
          {{  authorName === 'By default_author' || authorName === 'By ,' ? 'Author Unidentified' : authorName }}, <span class="source"> {{ source }} </span>
        </p>
      </div>
      <a-dropdown :trigger="['click']" class="article-card-new__header--dropdown" @click.stop="" placement="bottomRight" v-if="articleEllipsis">
        <a class="dropdown-link">
          <i class="icon icon-vertical_three-dots"></i>
        </a>
        <template #overlay>
          <a-menu>
            <a-menu-item key="0">
              <a class="remove-btn" @click="deleteArticleFromFolderContent(articleContentId)">Remove</a>
            </a-menu-item>
          </a-menu>
        </template>
      </a-dropdown>
    </div>
    <div class="article-card-new__body" :class="{'article-card-new-large__body': cardSize}">
      <p class="article-card-new__body--date" :class="{'article-card-new-large__body--date': cardSize}">Publish Date: <span>{{ date }}</span></p>
      <p class="article-card-new__body--description" :class="{'article-card-new-large__body--description': cardSize}">
        {{ text }}
      </p>
      <a-collapse
        v-model:activeKey="activeKey"
        :expand-icon-position="(expandIconPosition = 'right')"
        class="article-card-new__body--collapse"
      >
        <a-collapse-panel key="1" header="View Article Ratings" role="button">
          <article-ratings-card
          :loading="skeletonLoader"
          :isInlineRatingColumn="isInlineRatingColumn"
          :credibilityRatings="credibilityRatings"
          :accuracyRatings="accuracyRatings"
          :articleId="id"
          ></article-ratings-card>
        </a-collapse-panel>
      </a-collapse>
      <the-reaction-badge-counter
        v-if="Object.keys(totalArticleReactions).length > 0"
        :totalArticleReactions="totalArticleReactions"
        :userReactionRepresentation="userReactionRepresentation"
        :articleId="id">
      </the-reaction-badge-counter>
    </div>
    <div class="article-card-new__footer" :class="{'article-card-new-large__footer': cardSize}">
      <the-reactions
        :articleId="id"
        :userReaction="userReaction"
        @updateReaction="updateReaction"
        :isInsightArticle="isInsightArticle"
      ></the-reactions>
      <div
        v-if="showSaveBtn"
        @click="visiblesaveArticleModal()"
        class="article-card-new__footer--reactions article-card-new__footer--reactions-save"
        :class="{'article-card-new-large__footer--reactions-save': cardSize}"
      >
        <img
          src="@/assets/icons/bookmark.svg"
          alt="icon"
          style="width: 1.4rem !important;"
        />
        <p>Save</p>
      </div>
      <div
        v-if="showUnsaveBtn"
        @click="deleteArticleFromFolderContent(articleContentId)"
        class="article-card-new__footer--reactions article-card-new__footer--reactions-save article-card-new__footer--reactions-saved"
        :class="{ 'disableBtn' : onRemoveArticle }"
      >
        <img
          src="@/assets/icons/unsave-icon.svg"
          alt="icon"
          style="width: 1.4rem !important;"
        />
        <p>Unsave</p>
      </div>
      <div
        v-if="showShareButton"
        @click="visibleShareArticleModal()"
        class="article-card-new__footer--reactions article-card-new__footer--share-btn"
        :class="{'article-card-new-large__footer--reactions': cardSize}"
      >
        <img src="@/assets/icons/reply.svg" alt="icon" />
        <p>Share</p>
      </div>
      <div
        v-if="showShareButton"
        @click="visibleShareArticleDrawer()"
        class="article-card-new__footer--reactions article-card-new__footer--share-btn-mobile"
        :class="{'article-card-new-large__footer--reactions': cardSize}"
      >
        <img src="@/assets/icons/reply.svg" alt="icon" />
        <p>Share</p>
      </div>
      <div
        v-if="showDltBtn &&  isLoggedInUser"
        @click="closeShareArticle()"
        class="article-card-new__footer--reactions"
      >
        <i class="icon-dustbin icon"></i>
        <p>Delete</p>
      </div>
    </div>
  </div>

  <!-- Share article modal -->
  <a-modal
    v-model:visible="showArticleShareModal"
    :footer="null"
    wrapClassName="share-article-modal"
  >
    <article-share-modal
      :title="title"
      :image="image"
      :source="source"
      :url="url"
      :date="date"
      :id="id"
      @close-article-share-modal="closeArticleModal"
    ></article-share-modal>
  </a-modal>

  <!-- Save article modal -->
  <a-modal
    v-model:visible="showSaveArticleModal"
    :footer="null"
    wrapClassName="save-article-modal"
  >
  <article-save-modal
    :visible="showSaveArticleModal"
    @handle-close="showSaveArticleModal = false"
    :articleId="id"
  >
  </article-save-modal>
  </a-modal>

  <!-- Success or Failure message toaster -->
  <message-toaster
    :visible="showMessageModal"
    :message="message"
    @handle-close="showMessageModal = false"
  >
  </message-toaster>

  <!-- Delete shared article modal -->
  <delete-modal
    :visible="showArticleDeleteModal"
    @handle-close="showArticleDeleteModal = false"
    title="Are you sure you want to delete this article?"
    confirmText="Yes, Delete"
    cancelText="No, Don't Delete"
    :loading="onArticleDeleteRequest"
    @delete-record="deleteShareArticle"
  ></delete-modal>

  <!-- Share article drawer in mobile screen -->
  <article-share-drawer
    :dynamicClass="dynamicClass"
    :title="title"
    :image="image"
    :source="source"
    :url="url"
    :date="date"
    :id="id"
    :visible="showShareArticleDrawer"
    @close-share-article-drawer="closeShareArticleDrawer"
  ></article-share-drawer>


</template>

<script>
import ArticleShareModal from "./ArticleShareModal.vue";
import ArticleSaveModal from "./SaveArticleModal.vue";
import MessageToaster from "./MessageToaster.vue";
import TheReactions from "./TheReactions.vue";
import TheReactionBadgeCounter from './TheReactionBadgeCounter.vue';
import ArticleRatingsCard from "./ArticleRatingsCard.vue";
import DeleteModal from "./DeleteModal.vue";
import ArticleShareDrawer from "./ArticleShareDrawer.vue";
import folderMixin from '../../mixins/folders.mixnin.js';


export default {
  components: {
    ArticleShareModal,
    ArticleSaveModal,
    MessageToaster,
    TheReactions,
    ArticleRatingsCard,
    DeleteModal,
    TheReactionBadgeCounter,
    ArticleShareDrawer
  },
  props: {
    title: String,
    authorName: String,
    text: String,
    image: String,
    profilePhoto: String,
    sourceLogo: String,
    source: String,
    url: String,
    id: String,
    date: String,
    moreLines: Boolean,
    showShareButton: Boolean,
    showDltBtn: Boolean,
    showSaveBtn: Boolean,
    showUnsaveBtn: Boolean,
    ratings: Object,
    aggregatedRating: Object,
    userReaction: Object,
    totalArticleReactions: {
      type: Object,
      default: () => ({}),
    },
    userReactionRepresentation: String,
    folderId: String,
    savedArticleId: String,
    isInlineRatingColumn: Number,
    isInsightArticle: Boolean,
    cardSize: Boolean,
    articleContentId: String,
    articleEllipsis: {
      type: Boolean,
      default: false
    },
  
  },
  watch: {
    activeKey(val) {
      if (val.length > 1) {
        this.getArticleRatings();
      }
    },
  },
  data() {
    return {
      activeKey: 0,
      showArticleShareModal: false,
      showSaveArticleModal: false,
      showArticleDeleteModal: false,
      message: {},
      showMessageModal: false,
      skeletonLoader: false,
      accuracyRatings: {},
      credibilityRatings: {},
      onArticleDeleteRequest: false,
      showShareArticleDrawer: false,
      dynamicClass: 'activePortfolioTab',
      reRender: 0,
      onRemoveArticle: false,
    };
  },
  mixins: [folderMixin],
  mounted(){
    this.emitter.on('activeTabs', (val)=> {
      this.dynamicClass = val;
      console.log(this.dynamicClass);

    });
    this.emitter.on('close-article-share-modal', (val) => {
      this.closeArticleModal(val);
    });
    this.emitter.on('close-article-save-modal', (val)=> {
      this.closeSaveArticleModal(val);
    })
  },
  computed: {
    isLoggedInUser() {
      return this.$store.getters["profile/isLoggedinUser"];
    },
    searchTopic() {
      const topic = this.$store.getters["discover/getSearchedTopic"];
      return  topic === "" ? "Trending-news" : topic.replace(/\s+/g, '-');
    },
    sharedArticles: {
      get(){
        return this.$store.getters["article/getShareArticle"];
      },
      set(val){
        this.$store.commit('article/setShareArticles', val);
      }
    },
    folderArticles() {
      return this.$store.getters["folders/getFolderArticleContent"];
    },
  },
  methods: {
    updateReaction() {
      this.reRender = this.reRender + 1;
    },
    visibleShareArticleModal() {
      this.showArticleShareModal = true;
    },
    visibleShareArticleDrawer() {
      this.showShareArticleDrawer = true
    },
    closeShareArticleDrawer() {
      this.showShareArticleDrawer = false
    },
    closeArticleModal(val) {
      this.showArticleShareModal = val.modalVisibility;
      this.showShareArticleDrawer = val.modalVisibility;
      this.message = {
        title: val.title,
        type: val.type
      };
      this.showMessageModal = val.showMessageModal;
    },
    closeSaveArticleModal(val) {
      this.showSaveArticleModal = val.modalVisibility;
      this.message = {
        title: val.title,
        type: val.type
      }
      this.showMessageModal = val.showMessageModal;
    },
    visiblesaveArticleModal() {
      this.showSaveArticleModal = true;
    },
    visibleDeleteArticleModal() {
      this.showArticleDeleteModal = true;
    },
    // This is for portfolio article
    async deleteSavedArticle(folderId, id) {
      const unsavePayload = {
        save_article: [id],
        id: folderId
      };
      try {
        const response = await this.$store.dispatch(
          "article/deleteSavedArticle",
          unsavePayload
        );
        if (response.status === 200) {
          this.emitter.emit('savedArticleDeleted', id);
          this.emitter.emit('articleUnsaved');
          this.showMessageModal = true;
          this.message = {
            title: "Article unsaved",
            type: "success",
          };
        }
      } catch (err) {
        console.log(err);
      }
    },
    async deleteArticleFromFolderContent(articleId) {
      try {
        this.onRemoveArticle = true;
        const payload = { 
          folderId: this.$route.params.id,
          contentId: articleId
        }
        const response = await this.$store.dispatch('folders/deleteFolderContent', payload);
        if (response.status === 204) {
          const updatedArticleList = this.folderArticles.findIndex((val) => val.id === articleId);
          if (updatedArticleList !== -1) {
            this.folderArticles.splice(updatedArticleList, 1);
          }
          this.createActivity(articleId, 'folder_content', 'remove');
        }
          this.onRemoveArticle = false;
        }
      catch (err) {
        console.log(err);
      }
    },
    async openArticle() {
        const divElements =  document.getElementsByClassName('pdb-bg');

        if (divElements.length > 0) {
          Array.from(divElements).forEach(div => {
            div.scrollTop = 0;
          });
        }

        window.scrollTo(0,0);
        this.$emit('articleOpen')
        this.$router.push(`/discover/article/${this.searchTopic}/${this.id}`);
        this.$store.commit("discover/SET_ARTICLE_READ_VIEW", true);
    },
    async getArticleRatings() {
      let payload = {
        article_id: this.id,
      };
      try {
        this.skeletonLoader = true;
        const response = await this.$store.dispatch(
          "discover/getRating",
          payload
        );
        if (response.status === 200) {
          if(Object.keys(response.data).length > 1){
            this.accuracyRatings = response.data.choices[1];
            this.credibilityRatings = response.data.choices[0];
          } else {
            this.accuracyRatings = {};
            this.credibilityRatings = {};
          }
        }
        this.skeletonLoader = false;
      } catch (err) {
        console.log(err);
      }
    },
    deleteArticleModal() {
      this.showArticleDeleteModal = true;
    },
    async deleteShareArticle() {
      this.onArticleDeleteRequest = true;
      const deletePayload = {
        article_id: this.id,
      };
      try {
        const response = await this.$store.dispatch(
          "article/deleteShareArticle",
          deletePayload
        );
        if (response.status === 204) {
          const updatedList = this.sharedArticles.filter((val) => val.article.id !== this.id);
          this.sharedArticles = updatedList;
          this.message = {
            title: "Article Deleted",
            type: "success",
          };
          this.showArticleDeleteModal = false;
          this.showMessageModal = true;
          this.onArticleDeleteRequest = false;
        }
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>

<style lang="scss">
.article-card-new {
  max-width: 100%;
  border-radius: 1rem;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  height: fit-content;
  cursor: pointer;
  &__header {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    height: 100%;
    max-height: 30rem;
    min-height: 30rem;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    background-repeat: no-repeat;
    background-size: cover;
    background-position-x: center;
    background-position-y: center;
    background-color: $color-black;
    @include respond(laptop-large) {
      max-height: 24rem !important;
      min-height: 24rem !important;
    }
    @media (max-width: 1200px) {
      max-height: 24rem !important;
      min-height: 24rem !important;
    }
    @include respond(phone-x-small) {
      max-height: 26.3rem !important;
      min-height: 26.3rem !important;
    }

    &--title {
      font-size: 1.8rem;
      font-family: $font-primary-medium;
      font-weight: 700;
      line-height: 2.4rem;
      color: $color-white;
      font-weight: 700;
      margin-bottom: 0;
      padding: 0 1.2rem;
      padding-right: 5rem;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2; /* number of lines to show */
      line-clamp: 2;
      -webkit-box-orient: vertical;
      @include respond(tab-port) {
        font-size: 1.8rem !important;
        padding: 0 2.3rem !important;
        line-height: 2.2rem !important;
      }
    }
    &--auth-and-date {
      display: flex;
      align-items: center;
      padding: 2rem 1.2rem 1.7rem;
      @include respond(tab-port) {
        padding: 1.5rem 2.3rem 2rem !important;
      }
      img {
        width: 2.3rem;
        height: 2.3rem;
        border-radius: 100%;
        margin-right: 1rem;
        object-fit: fill;
        background-color: #d9d9d9;
        @include respond(laptop-large) {
          width: 3rem;
          height: 3rem;
        }
        @include respond(tab-port) {
          width: 3.7rem;
          height: 3.7rem;
        }
      }
      .logo-image {
        object-fit: contain;
        border-radius: 0;
        background-color: transparent;
        width: 2rem;
        height: 2rem;
      }
      p {
        font-size: 1.2rem;
        font-family: $font-primary;
        line-height: 1.8rem;
        color: #b6b6b6;
        margin-bottom: 0;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* number of lines to show */
        line-clamp: 2;
        -webkit-box-orient: vertical;
        .source {
          text-transform: uppercase;
        }
      }
    }
    &--dropdown {
      position: absolute;
      top: 1.5rem;
      right: 1rem;
      background-color: $color-white;
      width: 3rem;
      height: 3rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      .icon {
        color: $color-black;
        opacity: 1;
        font-size: 1.2rem;
      }
    }
  }
  &__body {
    background-color: $color-white;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-top: none;
    &--date {
      font-size: 1.3rem;
      font-family: $font-primary-medium;
      padding: 2rem 1.2rem 1rem;
      margin-bottom: 0;
      opacity: 0.8;
      cursor: default;
      line-height: normal;
      @include respond(tab-port) {
        padding: 2rem 2.3rem 1rem;
      }
      span {
        font-family: $font-primary;
      }
    }
    &--description {
      font-size: 1.4rem;
      font-family: $font-primary;
      line-height: 2rem;
      color: $color-black;
      padding: 0 1.2rem;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3; /* number of lines to show */
      line-clamp: 3;
      -webkit-box-orient: vertical;
      margin-bottom: 2rem;
      cursor: default;
      @include respond(tab-port) {
        padding: 0 2.3rem;
      }
    }
    .ant-collapse {
      border: none;
      background-color: $color-white;
      border-top: 1px solid rgba(0, 0, 0, 0.2) !important;
      .ant-collapse-item {
        border-radius: 0 !important;
        border: none;
        margin-bottom: 0 !important;
        .ant-collapse-header {
          margin: 0 !important;
          padding: 1.5rem 1.6rem !important;
          background-color: $color-white !important;
          border: none;
          border-radius: 0;
          width: fit-content;
          font-size: 1.6rem !important;
          font-family: $font-primary-medium !important;
          font-weight: 500;
          line-height: 2.4rem;
          color: $color-black;
          @include respond(tab-port) {
            padding: 1.5rem 2.3rem !important;
          }
          .anticon {
            right: -0.6rem;
            font-size: 1.4rem;
          }
          &::before {
            content: none;
          }
          &::after {
            content: none;
          }
        }
        .ant-collapse-content {
          border: none;
          padding: 0 !important;
          .ant-collapse-content-box {
            padding: 0 1.6rem 2rem !important;
            .ant-row {
              margin-bottom: 0;
            }
            @include respond(phone-x-small) {
              padding: 0 2.3rem 2rem !important;
            }
          }
        }
      }
    }
    .user-react {
      padding: 1rem 2rem;
      margin-top: 0;
      border-top: 1px solid rgba(0, 0, 0, 0.2);
    }
  }
  &__footer {
    display: flex;
    align-items: center;
    padding: 0;
    border-top: none !important;
    border: 1px solid rgba(0, 0, 0, 0.2);
    padding: 0 0.5rem;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    cursor: default;
    &--reactions {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 1.5rem 0;
      cursor: pointer;
      @include respond(laptop-large) {
        margin: 1.3rem 0;
      }
      .icon {
        color: $color-black;
        font-size: 1.4rem;
        margin-right: 1rem;
        @media (min-width: 1200px) and (max-width: 1300px) {
          margin-right: 0.5rem;
        }
      }
      img {
        width: 1.8rem;
        height: auto;
        margin-right: 1rem;
        @media (min-width: 1200px) and (max-width: 1300px) {
          margin-right: 0.5rem;
        }
      }
      p {
        font-size: 1.3rem;
        font-family: $font-primary-medium;
        line-height: 2rem;
        color: $color-black;
        margin-bottom: 0;
      }
    }
    &--reactions-save {
      border-right: 1px solid rgba(0, 0, 0, 0.2);
      border-left: 1px solid rgba(0, 0, 0, 0.2);
      margin: 1.5rem 0.3rem;
      @include respond(laptop-large) {
        margin: 1.2rem 0.3rem;
      }
      @include respond(phone-x-small) {
        margin: 1rem 0;
      }
    }
    // &--reactions-saved {
    //   border-right: 0;
    // }
    .disableBtn {
      opacity: 0.5;
      cursor: not-allowed;
    }
    &--share-btn {
      @include respond(tab-port) {
        display: none;
      }
    }
    &--share-btn-mobile {
      display: none;
      @include respond(tab-port) {
        display: flex;
      }
    }
  }
}
.article-card-new-large {
  @media (min-width: 1200px) and (max-width: 3000px) {
  &__header {
    max-height: 45rem;
    min-height: 45rem;
    @include respond(laptop-large) {
      max-height: 33rem !important;
      min-height: 33rem !important;
    }
    @media (max-width: 1200px) {
      max-height: 24rem !important;
      min-height: 24rem !important;
    }
    &--title {
      font-size: 2.4rem;
      line-height: 3rem;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3; /* number of lines to show */
      line-clamp: 3;
      -webkit-box-orient: vertical;
      padding: 0 2.3rem;
    }
    &--auth-and-date {
      padding: 1.8rem 2.3rem 2rem;
      img {
        width: 3.7rem;
        height: 3.7rem;
      }
      p {
        font-size: 1.2rem;
        font-family: $font-primary;
        line-height: 1.6rem;
      }
    }
  }
  &__body {
    &--description {
      padding: 0 2.3rem;
    }
    &--date {
      padding: 2rem 2.3rem 1rem;
    }
    .ant-collapse {
      .ant-collapse-item {
        .ant-collapse-header {
          padding: 2rem 2.3rem !important;
          @include respond(tab-port) {
            padding: 1.5rem 2.3rem !important;
          }
        }
        .ant-collapse-content {
          .ant-collapse-content-box {
            padding: 0 2.3rem 2rem !important;
          }
        }
      }
    }
    .user-react {
      padding: 1rem 2.3rem;
    }
  }
  }
}
</style>
